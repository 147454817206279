import React, {Component} from 'react';
import Ajax from "../../common/ajax";
import Config from "../../config";
import Content from "../../common/containers/Content";
import Row from "../../common/containers/Row";
import PrivatePage from "../../common/containers/PrivatePage";
import Col from "../../common/containers/Col";
import Chart from "react-google-charts";
import Waiting from "../../common/containers/Waiting";

class KnowledgeAreasReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            broaderAreas: null,
            areas: null,
            selectedBroaderArea: null,
            processing: true,
        };
        this.selectBroaderArea = this.selectBroaderArea.bind(this);
    }

    componentDidMount() {
        this.fetchBroaderArea();
    }

    fetchBroaderArea() {
        Ajax.get(`${Config.djangoHost}r/broader-area`).done(broaderAreas => {
            this.setState(state => ({
                ...state,
                broaderAreas,
            }));
        }).always(() => {
            this.setState(state => ({
                ...state,
                processing: false,
            }));
        });
    }

    selectBroaderArea(chartWrapper) {
        chartWrapper = chartWrapper.chartWrapper;
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        const dataTable = chartWrapper.getDataTable();
        const pieSliceLabel = dataTable.getValue(selection[0].row, 0);
        this.setState(state => ({...state, processing: true}));
        Ajax.get(`${Config.djangoHost}r/area/${pieSliceLabel}`).done(areas => {
            this.setState(state => ({
                ...state,
                areas,
                selectedBroaderArea: pieSliceLabel,
                processing: false,
            }));
        });
    }

    render() {
        return (
            <PrivatePage title="Knowledge Areas Report">
                <Content>
                    <Waiting isProcessing={this.state.processing}>
                        <Row>
                            <Col md={12}>
                                {this.state.broaderAreas && (
                                    <Chart
                                        height={500}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.broaderAreas}
                                        options={{
                                            title: "Broader areas (%)",
                                        }}
                                        legendToggle
                                        chartEvents={[
                                            {
                                                eventName: "select",
                                                callback: this.selectBroaderArea
                                            }
                                        ]}
                                    />
                                )}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                {this.state.areas && (
                                    <Chart
                                        height={500}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.areas}
                                        options={{
                                            title: `Areas for ${this.state.selectedBroaderArea} (%)`,
                                        }}
                                        legendToggle
                                    />
                                )}
                            </Col>
                        </Row>
                    </Waiting>
                </Content>
            </PrivatePage>
        );
    }
}

export default KnowledgeAreasReportPage;